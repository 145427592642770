<template>
  <div class="portfolio">
    <v-container>
      <v-row>
        <v-col>
          <h1 class="display-1 font-weight-bold pb-3">Projects</h1>
          <p class="headline text-750">
            From Wordpress &amp; Shopify to Vue or React, I have experience
            building and launching high performing client projects. Below is a
            sampling of client &amp; personal work from over the years.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <v-layout>
      <v-container>
        <!-- <v-row flex-wrap class="pa-s">
          <v-col xs="12">
            <v-card style="border-radius: 26px;" elevation="4">
              <v-row>
                <v-col class="col-1">
                  <v-btn
                    class="pa-6 ml-6 mt-2 black white--text"
                    icon
                    target="_blank"
                    :href="featured.link"
                  >
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn> 
                </v-col>
                <v-col class="col-11">
                <v-card-title class="pa-0 pt-1" width="100%">
                  {{featured.title}}
                </v-card-title> 
                <div>{{featured.subtext}}</div>
                </v-col>
              </v-row>
              
              <div class="pl-4 pb-3 featuredText">
                <v-text>Mobius is a non profit organization that focuses on providing educational and exciting content for kids and people of all ages. The goal of this website was to provide Mobius with a professional and easy to navigate website. That would showcase who they are. We also implemented an ordering system for Virutal Programs and Steam Kits, which are at home experiments kids can do while the center is closed due to covid 19. </v-text>
              </div>
              <v-img :src="featured.img" />
            </v-card>
          </v-col>
        </v-row> -->
        <v-row flex-wrap>
          <v-col :key="i" v-for="(item, i) in portfolioItems" md="4" sm="6" xs="12">
            <v-card class="portfolio-item">
              <v-img class="white--text portfolio-image" :src="item.img">
                <div class="d-flex justify-space-between card-info">
                  <div class="pl-5 pt-2 "> 
                    <p class="mb-0 subtitle-2">{{item.year}} • {{item.stack}}</p>
                    <p class="font-weight-black subtitle-1">{{item.title}}</p>
                  </div>
                  
                  <v-btn
                    class="ma-4 pa-4 white black--text"
                    x-small
                    icon
                    target="_blank"
                    :href="item.link"
                  >
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </div>
</template>
<script>
// import scannedMedia from "../assets/portfolio/scannedmedia.jpg";
import flootie from "../assets/portfolio/flootie.jpg";
// import crowncraft from "../assets/portfolio/crowncraft.jpg";
import idlekingdoms from "../assets/portfolio/idle-kingdoms.jpg";
// import desertjewels from "../assets/portfolio/desertjewelsnursery.jpg";
import quotes from "../assets/portfolio/quote-deck.jpg";
import mobius from "../assets/portfolio/MobiusFeature.jpg";
import svfd from '../assets/portfolio/svfd.png';
import uf from '../assets/portfolio/ultimate-flaps.png';
import babbs from '../assets/portfolio/Babbs-App.png';
import eig from '../assets/portfolio/dylan-insurance.png';
import spectrum from '../assets/portfolio/spectrum.jpg';
export default {
  data: () => ({
    // featured:{
    //   title: "Mobius Discovery Center",
    //   subtext: "Website Design & Development",
    //   link: "https://www.mobiusdiscoverycenter.org",
    //   img: mobius
    // },
    portfolioItems: [
      {
        year: "2022",
        stack: "PHP | WordPress | Divi",
        title: "Spokane Valley Fire Department",
        link: "https://spokanevalleyfire.com",
        img: svfd
      },
      {
        year: "2021",
        stack: 'Node | React | GraphQL',
        title: "Babbs TMS",
        link: "https://babbs.app",
        img: babbs
      },
      {
        year: "2021",
        stack: "React",
        title: "Dylan Insurance",
        link: "https://www.dylan-insurance.com",
        img: eig
      },
      {
        year: "2021",
        stack: "WordPress | Divi",
        title: "Ultimate Flaps",
        link: "https://ultimateflaps.com",
        img: uf
      },
      {
        year: "2020",
        stack: "WordPress | Elementor",
        title: "Mobius",
        link: "https://www.mobiusdiscoverycenter.org",
        img: mobius
      },
      {
        year: "2018",
        stack: "HTML | CSS",
        title: "Flootie",
        link: "https://www.flootie.com",
        img: flootie
      },
      {
        year: "2018",
        stack: "Vue",
        title: "Idle Kingdoms",
        link: "https://www.thinkingdesign.studio/portfolio/2018-idle-kingdoms/",
        img: idlekingdoms
      },
      {
        year: "2016",
        stack: "HTML | CSS",
        title: "SFCC Grad Show",
        link: "https://jeremyking.dev/class2016",
        img: spectrum
      },
      {
        year: "2016",
        stack: "jQuery",
        title: "Quote Deck",
        link: "https://www.thinkingdesign.studio/portfolio/2016-Quote-Deck/",
        img: quotes
      }
    ]
  })
};
</script>

<style lang="scss">

.featuredText {
  width: 750px;
}
.portfolio-item {
  border-radius: 8px !important;
  .card-info {
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    // height: 0px;
    height: 0px;
    transition: all 0.3s ease-in-out;
  }
}
.portfolio-item:hover {
  .card-info {
    height: 66px;
  }
}
.portfolio-image {
  height: 300px;
}
.text-750 {
  max-width: 750px;
}
@media only screen and (max-width: 768px) {
  .card-info {
    height: 66px !important;
  }
  .featuredText {
    width: 80%;
  }
  .portfolio-image {
    height: 200px;
  }
}
</style>
